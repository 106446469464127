/* You can add global styles to this file, and also import other style files */
@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// regular style toast
@import 'ngx-toastr/toastr';
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
}

html,
body {
  height: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
}

:root {
  --main-color: #344E71;
  --navbar-color: white;
  --background-color: white;
  --primary-color: #49c6de;
  --secondary-color: #49c6de;
  --active-color: #63CCC8;
  --config-color:#EAEAEA;
  --ft-primary-color: white;
  --ft-secondary-color: #41678b;

  $colors: (primary: #714fd0,
    danger: #e64b29,
    main: white,
    active: #7957d5,
  );

  @each $name,
  $glyph in $colors {
    .bg-#{$name} {
      background-color: $glyph !important;
    }

    .text-#{$name} {
      color: var(--main-color);
    }

    .border-#{$name} {
      border-color: $glyph !important;
    }

    .btn-#{$name} {
      background-color: $glyph !important;
    }
  }
}

select,
input:not([type="checkbox"]):not([type="radio"]),
textarea,
ng-select {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: rgba(121, 88, 211, 0.15) 1.95px 1.95px 2.6px;

  &:focus{
    outline: none !important;
    box-shadow: rgba(121, 88, 211, 0.15) 2.95px 2.95px 3.6px !important;
    border-color: #ccc !important;
    background-color: transparent;
  }

  &:active{
    outline: none !important;
    box-shadow: rgba(121, 88, 211, 0.15) 2.95px 2.95px 3.6px !important;
    border-color: #ccc !important;
    background-color: transparent;
  }

  &.ng-dirty.ng-invalid, &.ng-touched.ng-invalid{
    border-color: #ccc;
    box-shadow: rgba(255, 0, 0, 0.15) 2.95px 2.95px 3.6px !important;
  }

  &:disabled{
    background-color: #ccc !important;
    color: white !important;
    cursor: not-allowed !important;
  }
}

.pointer {
  cursor: pointer !important;
}


.form-group {
  position: relative;

  .text-muted,
  .text-danger {
    color: red !important;
  }

  input.ng-invalid.ng-touched,
  textarea.ng-invalid.ng-touched,
  select.ng-invalid.ng-touched {
    border: 1px solid #dc3545;
  }
}

.text-active{
  //color: var(--active-color) !important;
  color: var(--main-color) !important;
}

.no-select{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.search-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .child.lg{
    width: 30%;
  }

  .searchSelect{
    display: flex;
    flex-direction: row;

    select{
      width: 12vw;
      margin-right: 10px;
    }

    button{
      box-shadow: rgba(121, 88, 211, 0.15) 1.95px 1.95px 2.6px;
    }

    .ellipsis-btn{
      box-shadow: none !important;
      font-size: 20px;
      margin: 0 !important;
      padding: 0 !important;
      color:#ccc !important;

      margin-top: 2px !important;
      margin-left: 10px !important;

      &::after{
          content: none !important;
          display: none;
      }
    }
  }
}

.btn-active{
  background-color: var(--active-color) !important;
  color: white !important;
  font-weight: bolder;
}

.btn-outline-active{
  border: 1px solid var(--active-color) !important;
  color: var(--active-color) !important;
  font-weight: bolder;

  &:hover{
    background-color: var(--active-color) !important;
    color: white !important;
  }
}

.btn-outline-primary{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: var(--active-color);
  border: 1px solid rgba(99, 204, 200, 1);

  &:hover{
    color: white;
    background-color:rgba(99, 204, 200, 1);
    border: none;
  }

  &:active{
    color: white !important;
    background-color:rgba(99, 204, 200, 1) !important;
    border: none !important;
  }

  &:disabled{
    background-color: #9C9C9C;
    color: white;
    border: 1px solid #9C9C9C;
  }
}
/* ----- MODAL STYLE ----- */
.modal.right {
  .modal-dialog {
    display: flex;
    flex-direction: row;
    justify-content: center;
    //width: 23vw;
    overflow-y: hidden;

    border-radius: 15px;

    ::-webkit-scrollbar{
      display: none;
    }
  }

  .btn.close {
    font-weight: bolder;
    font-size: 1.5rem;
  }

  .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 7px;
    box-shadow: 0 4px 40px 0 rgba(33, 39, 44, 0.10);
  }

  .modal-body {
    padding: 20px;

  }

  /* Right */
  &.fade .modal-dialog {
    transition: opacity 0.3s linear, right 0.3s ease-out;
  }

  &.fade.in .modal-dialog {
    right: 0;
  }



  .modal-header {
    border-bottom-color: #EEEEEE;
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction:row-reverse;
  }

  .category-title{
    color: var(--main-color);
    font-size: 24px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
  }

  .modal-title-container {
    display: flex;
    flex-direction: column;
  }
  .required-asterisk{
    color:red;
    letter-spacing: 0.21px;

  }
  .subtitle-container {
    color: #9C9C9C;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .label-container{
    color: #262E36;
    font-size: 14px;
    font-style: normal;
    font-weight: 502;
    line-height: 100%; /* 14px */
  }
  .input-content{
    border-radius: 8px;
    border: 1px solid var(--grey-300, #D8DDE3);
    background: #FFF;
    box-shadow: 0 0 4px 0 rgba(23, 25, 28, 0.05);
    padding: 7px 16px;
    align-items: center;
  }

}

.form-btn-container {
  display: flex;
  padding: 15px 20px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}
.save-btn{
  background-color: #334E70!important;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  gap: 4px;
  color: white;
  border-radius: 5px;
  border: none;
}
.close-btn{
  display: flex;
  padding: 15px 20px;
  align-items: center;
  gap: 4px;
  background-color:  white !important;
  border: 1px solid #E3E3E3!important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #434343;
  border-radius: 5px;
}
.erase-btn{
  display: flex;
  padding: 15px 20px;
  align-items: center;
  gap: 4px;
  background-color: #E3E3E3;
  border: 1px solid #E3E3E3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #434343;
  border-radius: 5px;
}

.modal-form-content{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form-check-input:checked{
  background-color: var(--main-color) !important;
}
.btn-hide-modal{
  display:none;
}

/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
